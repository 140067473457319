import request from '@/utils/request'


// 查询用户动态评论列表
export function listDynamicComment(query) {
  return request({
    url: '/user/user-dynamic-comments/list',
    method: 'get',
    params: query
  })
}

// 查询用户动态评论分页
export function pageDynamicComment(query) {
  return request({
    url: '/user/user-dynamic-comments/page',
    method: 'get',
    params: query
  })
}

// 查询用户动态评论详细
export function getDynamicComment(data) {
  return request({
    url: '/user/user-dynamic-comments/detail',
    method: 'get',
    params: data
  })
}

// 新增用户动态评论
export function addDynamicComment(data) {
  return request({
    url: '/user/user-dynamic-comments/add',
    method: 'post',
    data: data
  })
}

// 修改用户动态评论
export function updateDynamicComment(data) {
  return request({
    url: '/user/user-dynamic-comments/edit',
    method: 'post',
    data: data
  })
}

// 删除用户动态评论
export function delDynamicComment(data) {
  return request({
    url: '/user/user-dynamic-comments/delete',
    method: 'post',
    data: data
  })
}
